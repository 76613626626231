import React, { useState } from "react";
import { TypeAnimation } from "react-type-animation";
import styled from "styled-components";

interface Props {
  text: string;
  hoverText: string;
  url: string;
  icon: string;
}

const LinkComponent = (props: Props) => {
  const [text, setText] = useState(props.text);

  return (
    <Link
      href={props.url}
      target="_blank"
      onMouseEnter={() => setText(props.hoverText)}
      onMouseLeave={() => setText(props.text)}
      onBlur={() => setText(props.text)}
      onFocus={() => setText(props.hoverText)}
    >
      <Img src={props.icon} alt={props.text} />
      <LinkText>{text}</LinkText>
      <TabImg src="./img/tab.svg" alt="New tab" />
    </Link>
  );
};

const links = [
  {
    text: "GitHub",
    hoverText: "@vojtechsisma",
    url: "https://github.com/vojtechsisma",
    icon: "/img/github.svg",
  },
  {
    text: "Instagram",
    hoverText: "@vojtasisma",
    url: "https://www.instagram.com/vojtasisma/",
    icon: "/img/ig.svg",
  },
  {
    text: "Strava",
    hoverText: "Strava",
    url: "https://www.strava.com/athletes/26111115",
    icon: "/img/strava.svg",
  },
  {
    text: "LinkedIn",
    hoverText: "/vojtechSisma",
    url: "https://www.linkedin.com/in/vojtechSisma",
    icon: "/img/linkedin.svg",
  },
  {
    text: "Email",
    hoverText: "Email",
    url: "mailto:vojta@vojtechsisma.eu",
    icon: "/img/mail.svg",
  },
];

function App() {
  return (
    <Main className="App">
      <Heading>
        <span className="wave">&#128075;</span>
        <Text
          className="type"
          sequence={[200, "Vojtěch Šišma", 1000]}
          cursor={true}
          repeat={Infinity}
        />
      </Heading>
      <Container>
        {links.map((link) => (
          <LinkComponent
            hoverText={link.hoverText}
            text={link.text}
            url={link.url}
            icon={link.icon}
            key={link.url}
          />
        ))}
      </Container>
    </Main>
  );
}

const Img = styled.img`
  width: 2rem;
  height: 2rem;
  margin-right: 1rem;
`;

const TabImg = styled.img`
  width: 1.5rem;
`;

const Main = styled.div`
  max-width: 50rem;
  padding: 1rem;
  margin: 0 auto;
`;

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;

  @media (min-width: 700px) {
    grid-template-columns: 1fr 1fr;
  }
`;

const Heading = styled.h1`
  font-size: 2rem;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  @media (min-width: 500px) {
    font-size: 4rem;
  }

  @media (min-width: 700px) {
    justify-content: left;
  }
`;

const Text = styled(TypeAnimation)`
  font-family: monospace;
  font-size: 2rem;
  margin-left: 1rem;
  width: 100%;
  text-align: left;

  @media (min-width: 500px) {
    font-size: 3rem;
  }
`;

const Link = styled.a`
  text-decoration: none;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  border: 0.25rem solid black;
  padding: 1rem 2rem;
  color: black;
  transition: all 0.3s;

  &:hover {
    color: white;
    background-color: black;
    transform: scale(1.025);
  }

  &:hover img {
    filter: invert(100%);
  }

  @media (prefers-color-scheme: dark) {
    color: white;
    border: 0.25rem solid white;

    &:hover {
      color: black;
      background-color: white;
      transform: scale(1.025);
    }

    img {
      filter: invert(100%);
    }

    &:hover img {
      filter: none;
    }
  }
`;

const LinkText = styled.span`
  font-size: 1.5rem;
  font-family: monospace;
  flex-grow: 1;
`;

export default App;
